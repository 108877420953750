<template>

  <div class="row"> <!-- spacer row -->
    <div class="rc-box-col-15 rc-text-value">
      <br />
    </div>
  </div> <!-- end spacer row -->

</template>

<script>

export default {
  name: 'spacer-row',
}
</script>